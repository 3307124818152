<template>
  <div
    class="
      flex
      justify-center
      space-x-1
      text-sm text-primary
      hover:text-purple-500
    "
  >
    <span class="text-black">
      {{ text_before ?? "" }}
    </span>

    <router-link class="" :class="$attrs.class" :to="to">
      <slot :class="$attrs.class" />
    </router-link>

    <span class="text-black">
      {{ text_after ?? "" }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    text_before: {
      type: String,
    },
    text_after: {
      type: String,
    },
    to: {
      default: "/",
    },
  },
};
</script>